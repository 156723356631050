import React from "react";

const Gallery = () => {
  const images = [
    "https://res.cloudinary.com/kadet/image/upload/q_100/v1645994536/ore-portfolio/Rectangle_908_zmpamz.png",
    "https://res.cloudinary.com/kadet/image/upload/q_100/v1645994537/ore-portfolio/Rectangle_909_pomfkf.png",

    "https://res.cloudinary.com/kadet/image/upload/q_100/v1645994536/ore-portfolio/Rectangle_908_1_ye2zm4.png",
    "https://res.cloudinary.com/kadet/image/upload/q_100/v1645994537/ore-portfolio/Rectangle_910_1_j9el6k.png",

    "https://res.cloudinary.com/kadet/image/upload/q_100/v1645994536/ore-portfolio/Rectangle_908_2_qshrrq.png",
    "https://res.cloudinary.com/kadet/image/upload/q_100/v1645994537/ore-portfolio/Rectangle_910_2_pqvjsq.png",

    "https://res.cloudinary.com/kadet/image/upload/q_100/v1645994536/ore-portfolio/Rectangle_908_3_pqvqyu.png",
    "https://res.cloudinary.com/kadet/image/upload/q_100/v1645985036/ore-portfolio/Rectangle_909_dj4bbt.svg",

    "https://res.cloudinary.com/kadet/image/upload/q_100/v1645994536/ore-portfolio/Rectangle_908_4_r8hq7n.png",
    "https://res.cloudinary.com/kadet/image/upload/q_100/v1645994537/ore-portfolio/Rectangle_910_1_j9el6k.png",
  ];

  return (
    <div className="preloader__gallery">
      <div
        data-animation="preloader-gallery"
        className="preloader__gallery-images"
      >
        {images.map((item, index) => (
          <div
            className={
              index % 2 === 0
                ? "preloader__gallery-image"
                : "preloader__gallery-image--small"
            }
            data-animation={
              index % 2 === 0
                ? "preloader-gallery-image"
                : "preloader-gallery-smallimage"
            }
          >
            <img data-src={item} key={index} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
