import React, { useState, useEffect, useCallback } from "react";
import { checkDeviceType, eventEmitter } from "../../utils";

import TextLogo from "./textlogo";
import Gallery from "./gallery";

const Preloader = ({ children }) => {
  const [hasPreloaded, setHasPreloaded] = useState(false);

  const init = useCallback(async () => {
    // check if device is mobile
    const width = window.innerWidth;
    const ismobile = checkDeviceType() === "mobile" || width <= 700;

    // import animations
    const App = (await import("../../animations")).App;

    // initialize new animation
    new App({ ismobile });
  }, []);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const mount = () => {
      setHasPreloaded(true);
    };

    eventEmitter.once("preload-completed", mount);
  }, []);

  return (
    <>
      <div data-animation="preloader" className="preloader">
        <TextLogo />
        <Gallery />
      </div>
      {children}
    </>
  );
};

export default Preloader;
